
import {defineComponent, PropType} from 'vue';
import {formatCurrencyAndQuantity, getErrorMsg, swalNotification} from '@/core/helpers/utils';
import {IVatTuResponseBase} from '@/core/interfaces/ApiResponses';
import {useRouterCustom} from '@/core/hooks/use-router-custom';
import {ButtonsType, ButtonTypeColors} from '@/core/enums/buttons.enums';
import {VatTuService} from '@/core/services/VatTu.service';
import { usePagination } from '@/core/hooks/use-pagination';

import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import ConfirmationDialog from '@/components/confirmation-dialog/ConfirmationDialog.vue';
import useVatTu from '@/core/hooks/use-vat-tu';

export default defineComponent({
	name: 'danh-sach-vat-tu-table',

	components: { ButtonCustom, ConfirmationDialog },

	emits: ['delete-vat-tu-success'],

	props: {
		curPage: {
			type: Number,
			default: 0,
		},
		items: {
			type: Array as PropType<Array<IVatTuResponseBase>>,
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		perPage: {
			type: Number,
			default: 0,
		},
		showPrice: {
			type: Boolean,
			default: false,
		},
		showSoDu: {
			type: Boolean,
			default: false,
		},
    vatTuPhieuNhapDetail: {
      type: Boolean,
      default: false
    },
	},

	setup() {
		const { push } = useRouterCustom();

		const { indexCal } = usePagination();

    const { isTextDangerColumn, isValidLabel } = useVatTu();

		return {
      push,
      indexCal,
      isTextDangerColumn,
      isValidLabel,
    };
	},

	data() {
		return {
			isDeleteVatTuDialogDisplay: false,
			deletingVatTu: null as IVatTuResponseBase | null,
			vatTuItems: this.items as IVatTuResponseBase[],
			ButtonsType, ButtonTypeColors
		}
	},

	watch: {
		items(value) {
			this.vatTuItems = value;
		},
	},

	computed: {
		isVatTuEditable() {
			return process.env.VUE_APP_IS_EDIT_VAT_TU_ENABLE === 'true';
		},

		actionColumnWidth() {
			if (this.isVatTuEditable) return 100;

			return 50;
		}
	},

	methods: {
		formatCurrencyAndQuantity,

		onDeleteVatTuClicked(vatTuId) {
			const vatTu = this.vatTuItems.find(item => item.id === vatTuId);
			this.isDeleteVatTuDialogDisplay = true;
			if (vatTu) {
				this.deletingVatTu = vatTu;
			}
		},

		async confirmDelete() {
			this.isDeleteVatTuDialogDisplay = false;
			try {
				if (this.deletingVatTu) {
					await VatTuService.delete(this.deletingVatTu?.id);
				}
				await swalNotification(
					'Xóa vật tư thành công',
					'success'
				);
				this.$emit('delete-vat-tu-success');
			} catch (e) {
				await swalNotification(
					getErrorMsg(e, 'Có lỗi xảy ra, không thể xóa vật tư này'),
					'error'
				);
			}
		}
	},
})
